.dd .dropdown {
    position: relative;
    display: inline-block;
  }
  .dd ul {
    list-style-type: none;
      margin: 0;
      padding: 0;
      top: 33px;
      right: 0px;
      width: 150px;
      background-color: #ffffff;
      font-weight: bold;
      position: absolute;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      display: block;
      text-align: right;
      height: auto;
      border-radius: 8px;
      border:solid 1px rgba(36,152,237,0.8);
      
  }
.center{
  text-align:center;
}
.br-top{
  border-top:solid 1px #ddd;
}
 
  .dd li a {
    color: #000;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    margin: 0 auto;

  }
  .dd li {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: normal;
    
  }
  .dd li:last-child {
    border-bottom: none;
  }
  .dd li:hover {    
    color:rgba(36,152,237,0.8);
  }
  