@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
:root {
    --theme-bg-color: /*#71d056*/ #56af43;
    --clear-menu-bg-color: #fff;
    --page-bg-color:#f1f1f1/*#eaeef3*/;
    --side-menu-border:#ddd;
    --ex-dark-color:#282828;
    --dark-color:#5e5e5e;
    --light-color:#999;
    --ex-light-color:#eee;
    --yellow:/*#f6bc41*/#F9AA25;
    --default-font-size:14px;
    --medium-font-size:16px;
    --light-weight:200;
    --normal-weight:400;
    --semibold-weight:600;
    --bold-weight:800;
    --placeholder-color:#ddd;
    --red:#ef4836;
    --blue:/*#51B7E1*/#1fd7ea;
    --table-border:#dee2e6;
    --dark-grey:#666;
}
.blue {
  background: var(--blue);
  color: var(--clear-menu-bg-color);
}
html,body {
  height: 100%;
  min-height: 100%;
  position: relative;
  background: var(--page-bg-color);
}
body {
  font-family: "Roboto";
  font-size: var(--default-font-size);

}
:focus {
  outline: 0px;
}
.page-container {
    height: 100%;
    width: 100%;
    display: table;
    table-layout: fixed;
}
.page-container .sidebar-menu .logo-env {
  padding: 10px;
}
.page-container .main-content {
    background: var(--page-bg-color);
    float: left;
    width: 100%;
    padding: 15px 0;
    /*width: 100%;
    height: 100%;*/
}
a {
  color: var(--dark-color);
}
.page-container .sidebar-menu #main-menu li {
    position: relative;
    margin: 0;
    font-size: 12px;
    border-bottom: solid 1px var(--side-menu-border);
}
.page-container .sidebar-menu #main-menu li, .page-container .sidebar-menu #main-menu li ul > li, .page-container .sidebar-menu #main-menu li ul {
  border-color: var(--side-menu-border);
}
.page-container .sidebar-menu, .page-container .sidebar-menu #main-menu li ul > li > a {
  background: var(--clear-menu-bg-color);
}
.page-container .sidebar-menu #main-menu li a {
  color: var(--ex-dark-color);
}
.page-container .sidebar-menu #main-menu li.active > a, .page-container .sidebar-menu #main-menu li a:hover, .profile-info.dropdown .dropdown-menu li a:hover {
  background: var(--ex-light-color);
  color: var(--ex-dark-color);
}
.top-menu {
    background: #fff;
    border-bottom: solid 1px var(--placeholder-color);
    /* box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1), 0 2px 10px 0 rgba(0,0,0,0.08);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1), 0 2px 10px 0 rgba(0,0,0,0.08); */
    height: 53px;
}
.top-menu.admin {
  box-shadow: none;
  position: static;
 }
.wrap-inner-content {
    /*padding: 10px;*/
    clear: both;
    float: left;
    width: 100%;
}
.wrap-inner-content.admin {
  margin-top: 0;
}
.inner-content {
  background: var(--clear-menu-bg-color);
  padding: 15px;
  margin-bottom: 15px; /*for view patient profile*/
  float: left;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .16);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .16);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .16);
  -ms-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .16);
  /*box-shadow: 0 10px 20px rgba(0,0,0,.1);
  -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
  -moz-box-shadow: 0 10px 20px rgba(0,0,0,.1);
  -ms-box-shadow: 0 10px 20px rgba(0,0,0,.1);*/

 }
.dropdown-menu { /*right: 0px;*/ }
.profile-info.dropdown .dropdown-menu, .more-option > .dropdown-menu {
    border: none;
    padding: 0;
    color: var(--clear-menu-bg-color);
    right: 0;
    left: inherit;
    margin: -1px 0 3px;
    border-radius: 0px;
    box-shadow: none;
}
.profile-info.dropdown .dropdown-menu:before, .more-option > .dropdown-menu:before {
    top: -17px;
    right: 9px;
    left: auto;
    border: 8px solid transparent;
    border-bottom-color: var(--ex-light-color);
    position: absolute;
    content: '';
    display: inline-block;
}
.profile-info.dropdown .dropdown-menu > li, .more-option > .dropdown-menu > li {
  border-color:var(--clear-menu-bg-color) ;
}
.more-option > .dropdown-menu li a {
  color: var(--clear-menu-bg-color) ;
}
.profile-info.dropdown .dropdown-menu > li > a {
    display: block;
    min-width: 210px;
}
.btn {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
.btn:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .2), 0 3px 6px rgba(0, 0, 0, .26);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .2), 0 3px 6px rgba(0, 0, 0, .26);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, .2), 0 3px 6px rgba(0, 0, 0, .26);
    -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, .2), 0 3px 6px rgba(0, 0, 0, .26);
}
.yellow-color {
  color: var(--yellow);
  }
.green-color {
  color: var(--theme-bg-color);
}
.red-color {
  color: var(--red);
}
.green-bg, .yellow-bg, .red-bg {
  color: var(--clear-menu-bg-color);
}
.green-bg {
  background: var(--theme-bg-color);
}
.yellow-bg {
  background: var(--yellow);
}
.red-bg {
  background: var(--red);
}
.green, .yellow, .red {
  color:var(--clear-menu-bg-color);
}
.text-btn {
  text-transform: capitalize;
  font-size: var(--medium-font-size);
  padding: 8px 15px;
  min-width: 100px;
  border-radius: 0px;
}
.btn.green {
  background-color: var(--theme-bg-color);
  border-color: var(--theme-bg-color)
}
.btn.green:hover, .btn.green:focus, .btn.yellow:hover, .btn.yellow:focus, .btn.red:hover, .btn.red:focus  {
  color: var(--clear-menu-bg-color);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}
.btn.yellow {
  background-color: var(--yellow);
  border-color:var(--yellow);
}
.btn.red {
  background-color: var(--red);
  border-color:  var(--red);
}
.table-bordered, .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border-color:var(--table-border);
  font-size: var(--default-font-size);
  color: var(--dark-color);
  vertical-align: middle;
}
/*.table-bordered, .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td, thead:first-child > tr:first-child > th {
  border-color: #bbb !important;
  color:#5e5e5e;
}*/
.clickable-row {
  cursor: pointer;
}
.table tbody tr:nth-of-type(odd), .table tbody tr:hover {
    background-color: rgba(0,0,0,.03);
}
tbody td strong, .remove-border th { color: var(--ex-dark-color); }
.table-bordered > thead > tr > th {
  color: var(--ex-dark-color);
  font-weight: var(--semibold-weight);
  text-align: left;
  background: none;
}
.icon-btn {
    margin: 0 3px;
    padding: 5px;
    width: 30px;
    height: 30px;
    line-height: 13px;
    font-size: 16px;
}
h2 { margin: 0 0 0px; }
h3 { margin: 10px 0 20px; }
footer.main { padding: 0 20px; }
hr {
  clear: both;
  border-color: var(--light-color);
  display: inline-block;
  width: 100%;
}
.table-bordered { border: none; }
.form-group {
    display: table;
    width: 100%;
}
thead:first-child > tr:first-child > th { border-top: solid 1px var(--table-border) !important; }
.remove-border, .remove-border td, .remove-border th, .remove-border thead:first-child > tr:first-child > th { border: 0px !important; padding: 3px !important }
.control-label { display: table-header-group; font-weight: var(--normal-weight); font-size: var(--default-font-size); color: var(--light-color); }
.form-control { display: table-row-group; height: 40px; border-color:var(--light-color); color: var(--ex-dark-color); font-size: var(--medium-font-size); margin-top: 5px; border-radius: 0px; -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;}
.form-control:focus {
  border-color: var(--ex-dark-color);
}
.form-control:focus + label { color: var(--ex-dark-color); }
input[tupe="text"]::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-size: var(--default-font-size);
}
input[tupe="text"]::-moz-placeholder {
  color: var(--placeholder-color);
  font-size: var(--default-font-size);
}
input[tupe="text"]:-ms-input-placeholder {
  color: var(--placeholder);
  font-size: var(--default-font-size);
}
input[tupe="text"]:-moz-placeholder {
  color: var(--placeholder-color);
  font-size: var(--default-font-size);
}
.form-inline-btn { margin-top: 25px; }
.nav.nav-pills.nav-stacked {
  /*background: var(--ex-light-color);*/
  font-size: var(--medium-font-size);
  padding: 0px;

}
.nav-pills > li.active > a, .nav-pills > li.active > a:focus {
  background-color:var(--theme-bg-color); border: none;
}
.nav-pills li a {
    border-radius: 0px;
    color: var(--dark-color);
    border-left: solid;
    background: rgba(0,0,0,.05);
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 18px;
  width: 18px;
  position: relative;
  transition: all 0.15s ease-out 0s;
  background: var(--clear-menu-bg-color);
  border: solid 2px var(--theme-bg-color);
  color: var(--clear-menu-bg-color);
  cursor: pointer;
  display: inline-block;
  outline: none;
}
.option-input:checked {
  background: var(--theme-bg-color);
  margin: 0px;
}
.option-input:checked::before {
    height: 18px;
    width: 18px;
    position: absolute;
    content: '✔';
    display: inline-block;
    font-size: 13px;
    text-align: center;
    line-height: 18px;
    left: -2px;
    top: -2px;
    font-weight: normal;
}
.option-input:checked::after {
  background: var(--theme-bg-color);
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}
.option-input.radio, .option-input.radio:checked::before {
    height: 18px;
    width: 18px;
    margin-top: 0px;
}
.option-input.radio:checked::before {
line-height: 18px;
}
input[type="checkbox"]:focus, input[type="radio"]:focus {
  outline: inherit;
  outline-offset: 0px;
}
input[type="checkbox"] { margin:1px 5px !important; }
.check-list-text {
  font-size: var(--medium-font-size);
}
.check-list-input, .check-list-text {
    float: left;
}
.check-list-input { margin:1px 10px !important; }
.check-list-radio {
    clear: both;
    margin-top: 10px;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}
.option-input.radio {
    float: left;
    margin: 0 10px;
}
.check-list-radio div {
    float: left;
}
.tab-content {
    /* max-height: 575px;*/
    overflow-y: auto;
    overflow-x: hidden;
}

.logo {
    margin: 5px 0;
}
.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
    border-bottom-width: 0px !important;
}
.user-info {
    padding: 0;
    margin: 0;
}
.user-info li {
  list-style: none;
  display: inline-block;
}
.user-info .dropdown-menu li {
  display: block;
}
.user-info.nav .dropdown-menu li a {
/*  display: block;
  padding: 5px;*/
}
li.profile-info img {
    width: 44px;
    height: 44px;
    border: 2px solid #f5f5f5;
    margin-right: 5px;
}
.medium-size { width: 130px; }
label { margin-bottom: 0px; cursor: pointer; }
label span {
    float: right;
    margin: 3px 0px 0 1px;
    font-weight: normal;
    font-size: 12px;
}
.checkbox-listing {
    margin-bottom: 10px;
    padding: 10px 0px 5px;
}
.checkbox-listing:hover {
  background: var(--ex-light-color);
 }
 .checkbox-listing:last-child .col-md-8 { margin-top: 5px; }
 .logo img {
    max-width: 100%;
}
h2 span {
    clear: both;
    font-size: var(--medium-font-size);
    color: var(--light-color);
    display: block;
    margin-top: 10px;
    font-weight: var(--normal-weight);
    line-height: 22px;
}
h2 span svg {
  font-size: 16px !important;
}
#encounter hr {
    margin: 20px 0;
    display: inline-block;
    width: 100%;
}
#encounter li { line-height: 25px; list-style-type: disc; }
#encounter li:last-child { list-style-type: none; }
.medicine-detail {
    border: solid 1px;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 5px 0;
}
.medicine-date {
    font-size: var(--medium-font-size);
    margin: 0 10px;
}
.btn-circle { border-radius: 50% !important; }

.grey { background: var(--light-color); color: var(--clear-menu-bg-color); }
.medicine-detail .btn {
    text-align: center;
    padding: 5px 9px;
    margin-bottom: 7px;
}
.medicine-detail .btn:last-child {
    padding: 5px 10px;

}
.medicine-detail-heading { border-bottom: solid 1px var(--light-color); overflow: hidden; }
.medicine-details-inner {
    padding: 15px;
    overflow: hidden;
    font-size: var(--medium-font-size);
}
.medicine-details-inner li {
    list-style-type: disc;
    margin-left: 25px;
}
.medicine-details-inner .col-md-12 {
    margin-top: 15px;
}
#encounter .medicine-details-inner li { margin-left: 20px; }
#encounter .medicine-details-inner li:last-child { list-style-type:disc;  }
.add-new-medicine {
    clear: both;
    position: relative;
    margin-bottom: 10px;
}
.add-icon {
    border: dashed 2px #999;
    padding: 6px 10px;
    margin: 5px;
    display: inline-block;
}
.replace-file {
    font-size: var(--medium-font-size);
    display: inline-block;
    cursor: pointer;
}
.file-upload svg {
    width: 75px !important;
}
.file-upload {
    border: dashed 2px #999;
    padding: 20px 10px 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    cursor: pointer;
}
.before-login { margin: 50px 0 20px; }
.before-login small { font-size: 22px; color: var(--dark-color); font-weight:200; display: block; }
.before-login h1 { font-weight:var(--light-weight); color: var(--ex-dark-color); }
.before-login h1 span { font-weight: var(--semibold-weight); color: var(--theme-bg-color); }
.feilds-box { background: var(--clear-menu-bg-color); padding: 40px; margin: 20px; border-radius: 0px;
  -moz-box-shadow: 0px 5px 20px rgb(100,100,100,0.2);
-webkit-box-shadow: 0px 5px 20px rgb(100,100,100,0.2);
box-shadow: 0px 5px 20px rgb(100,100,100,0.2);

}
.password-field { position: relative; }
.forgot-password {position: absolute; top: 0px; right: 0px;}
.visit-list-section h3, .view-visit-section h3 {
    padding: 10px 0;
    border-bottom: solid 1px var(--ex-dark-color);
}
.visit-list-wrap {
    margin-top: 30px;
    font-size: 18px;
    color: var(--ex-dark-color);
    font-weight: var(--semibold-weight);
}
.visit-list span {
    display: block;
    margin: 0 0 0px 25px;
    font-size: 14px;
    color: var(--light-color);
    font-weight: var(--normal-weight);
    font-style: italic;
}
.visit-list svg {
    width: 14px !important;
    margin-right: 5px;
}
.visit-list {
    margin-bottom: 20px;
}
.view-visit-section svg {
    width: 17px !important;
    margin-right: 5px;
}
.view-visit-section span{

    font-size: 14px;
    color: var(--light-color);
    font-weight: var(--normal-weight);
    font-style: italic;
}
.prescription-view {
    margin-top: 30px;
    max-height: 500px;
    overflow-y: auto;
}
.prescription-view img { max-width: 100%; }
.visit-list-section, .view-visit-section {

}
.prescription-view h4 { border-bottom: solid 1px var(--ex-light-color); padding-bottom: 10px; }
.remove-padding { padding: 0px; }
.patient-info h2 {
    float: left;
    margin-right: 10px;
}
.patient-address {
    float: left;
    border-left: solid 1px var(--dark-color);
    padding-left: 10px;
    font-size: var(--medium-font-size);
    line-height: 25px;
}
.responsive-image {
    max-width: 100%;
}
.table-btn {
  min-width: 60px;
}
.text-center {
    text-align: center !important;
}
span.inline {
    float: left;
    width: 48%;
    margin-right: 2px;
}
.checkbox-section {
    margin-top: 10px;
}
.form-control.ild-select {
    padding: 5px;
}
span.medicine-date input {
    margin-top: 3px !important;
    display: inline-block;
}
.margin-top-30 { margin-top: 30px; }
.box {
    padding: 10px;
    max-height: 290px;
    margin: 10px 0;
    overflow-y: auto;
    background: var(--clear-menu-bg-color);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .16);
}
.box h3 {
    margin: 4px 0 0;
    padding: 0px;
    border: none;
}
.box-header {
    border-bottom: solid 1px var(--light-color);
    overflow: hidden;
    margin: -10px -10px 10px;
    padding: 4px 10px;
  }
.box-header .col-md-6:last-child svg {
    margin: 3px -10px 0 0 !important;
    transform: rotate(50deg);
    width: 10px !important;
    color: var(--ex-dark-color);
    cursor: pointer;
}
.wrap-inner-content .box.first {
    min-height: auto;
}
.vitals svg {
  transform: rotate(50deg);
  margin: 10px 0px 0 0 !important;
}
.patient-details { background: var(--clear-menu-bg-color);  padding: 10px; overflow: hidden; clear: both; margin-bottom: 20px;}

/*Doctor Edit Wizard*/
.form-wizard {
    padding: 0px;
    background: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #888;
    text-align: center;
}
.form-body-classic .form-control:focus {
    outline: 0;
    background: #f8f8f8 none repeat scroll 0 0;
    border: 1px solid #eee;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}
.form-body-stylist .form-control {
    height: 44px;
    margin: 0;
    padding: 0 20px;
    background: #e1e1e1; /* Old browsers */
    background: -moz-linear-gradient(top, #e1e1e1 0%, #f1f1f1 50%, #e1e1e1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#e1e1e1',GradientType=0 ); /* IE6-9 */
    border: 1px solid #ddd;
    font-family: 'Roboto', FontAwesome;
    font-size: 16px;
    font-weight: 300;
    line-height: 44px;
    color: #888;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-stylist .form-control:focus {
    outline: 0;
    background: #e1e1e1; /* Old browsers */
    background: -moz-linear-gradient(top, #e1e1e1 0%, #f1f1f1 50%, #e1e1e1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e1e1e1 0%,#f1f1f1 50%,#e1e1e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#e1e1e1',GradientType=0 ); /* IE6-9 */
    border: 1px solid #ccc;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-stylist.form-wizard .btn {
    margin: 0;
    padding: 3px 15px;
    border: 2px solid #ccc;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 0px; -webkit-border-radius: 0px; border-radius: 25px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

/** Form Body Stylist **/



/** Form Body Material **/

.form-body-material .form-control {
    height: 44px;
    margin: 0;
    padding: 0 20px;
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0px;
    border-bottom: 2px solid #ddd;
    font-family: 'Roboto', FontAwesome;
    font-size: 16px;
    font-weight: 300;
    line-height: 44px;
    color: #888;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-material .form-control:focus {
    outline: 0;
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0px;
    border-bottom: 2px solid #fd423f;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

.form-body-material.form-wizard .btn {
    min-width: 105px;
    height: 40px;
    margin: 0;
    padding: 0 20px;
    border: 2px solid #ccc;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

/** Form Body Material **/




/** Form header Classic **/

.form-header-classic .form-wizard-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    background: #ddd;
    font-size: 16px;
    color: #fff;
    line-height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-weight: 200;
}
.form-header-classic .form-wizard-step.activated .form-wizard-step-icon {
    background: #fff;
    border: 1px solid #999;
    color: #999;
    line-height: 38px;
}
.form-header-classic .form-wizard-step.active .form-wizard-step-icon {
    width: 48px;
    height: 48px;
    margin-top: 0;
    background: #999;
    font-size: 22px;
    line-height: 48px;
    font-weight: 500;
}

.form-header-classic .form-wizard-step p { color: #ccc; font-weight: 200; }
.form-header-classic .form-wizard-step.activated p { color: #999; }
.form-header-classic .form-wizard-step.active p { color: #999; font-weight: 500; }

/** Form header Classic **/



/** Form header Modarn **/

.form-header-modarn .form-wizard-step{
    padding-top:10px !important;
    border:2px solid #fff;
    background:#ccc;
}
.form-header-modarn .form-wizard-step.active{
    background:#fd423f;
}
.form-header-modarn .form-wizard-step.activated{
    background:#fd423f;
}
.form-header-modarn .form-wizard-progress {
    height: 0px;
    display:none;
}
.form-header-modarn .form-wizard-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    background: #ddd;
    font-size: 16px;
    color: #777;
    line-height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.form-header-modarn .form-wizard-step.activated .form-wizard-step-icon {
    background: #fd423f;
    border: 1px solid #fff;
    color: #fff;
    line-height: 38px;
}
.form-header-modarn .form-wizard-step.active .form-wizard-step-icon {
    background: #fff;
    border: 1px solid #fff;
    color: #fd423f;
    line-height: 38px;
}

.form-header-modarn .form-wizard-step p { color: #fff; }
.form-header-modarn .form-wizard-step.activated p { color: #fff; }
.form-header-modarn .form-wizard-step.active p { color: #fff; }

/** Form header Modarn **/



/** Form header Stylist **/

.form-header-stylist .form-wizard-step{
    position:relative;
    padding-top:10px !important;
    border:0px solid #fff;
    background:#ccc;
}
.form-header-stylist .form-wizard-step::before {
    content: '';
    position: absolute;
    left: 0%;
    top: -2%;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 20px solid #ccc;
    clear: both;
    z-index: 999;
}
.form-header-stylist .form-wizard-step.active::after {
    content: '';
    position: absolute;
    right: -20px;
    top: -2%;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 20px solid #fd423f;
    clear: both;
    z-index: 9999;
}
.form-header-stylist .form-wizard-step.activated::after {
    content: '';
    position: absolute;
    right: -20px;
    top: -2%;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 20px solid #fd423f;
    clear: both;
    z-index: 9999;
}
.form-header-stylist .form-wizard-step.active{
    background:#fd423f;
}
.form-header-stylist .form-wizard-step.activated{
    background:#fd423f;
}
.form-header-stylist .form-wizard-progress {
    height: 0px;
    display:none;
}
.form-header-stylist .form-wizard-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    background: #ddd;
    font-size: 16px;
    color: #777;
    line-height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.form-header-stylist .form-wizard-step.activated .form-wizard-step-icon {
    background: #fd423f;
    border: 1px solid #fff;
    color: #fff;
    line-height: 38px;
}
.form-header-stylist .form-wizard-step.active .form-wizard-step-icon {
    background: #fff;
    border: 1px solid #fff;
    color: #fd423f;
    line-height: 38px;
}

.form-header-stylist .form-wizard-step p { color: #fff; }
.form-header-stylist .form-wizard-step.activated p { color: #fff; }
.form-header-stylist .form-wizard-step.active p { color: #fff; }

/** Form header Stylist **/
.form-wizard strong { font-weight: 500; }
.form-wizard h1, .form-wizard h2 {
    margin-top: 10px;
    font-size: 38px;
    font-weight: 100;
    color: #555;
    line-height: 50px;
}

.form-wizard h3 {
    font-size: 25px;
    font-weight: 300;
    color: #fd423f;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.form-wizard h4 {
    float:left;
    font-size: 20px;
    font-weight: 400;
    color: #4b5255;
    line-height: 26px;
    width:100%;
}
.form-wizard h4  span{
    float:right;
    font-size: 18px;
    font-weight: 300;
    color: #555;
    line-height: 26px;
}

.form-wizard table tr th{font-weight:normal;}

.form-wizard img { max-width: 100%; }

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
    position: absolute;
    margin-top: 9px;
    margin-left: -20px;
}

.form-control option:hover, .form-control option:checked  {
    box-shadow: 0 0 10px 100px #fd423f inset;
}

.form-control:-moz-placeholder { color: #888; }
.form-control:-ms-input-placeholder { color: #888; }
.form-control::-webkit-input-placeholder { color: #888; }


.form-wizard label span { color:#e53935; }

.form-wizard .btn:focus,
.form-wizard .btn:active:focus,
.form-wizard .btn.active:focus {
    outline: 0;

    color: #fff;
}


.form-wizard .btn.btn-previous,
.form-wizard .btn.btn-previous:focus,
.form-wizard .btn.btn-previous:active:focus,
.form-wizard .btn.btn-previous.active:focus {
background: #bbb;
color: #fff;
}

.form-wizard .success h3{
    color: #fd423f;
    text-align: center;
    margin: 20px auto !important;
}
.form-wizard .success .success-icon {
    color: #fd423f;
    font-size: 100px;
    border: 5px solid #fd423f;
    border-radius: 100px;
    text-align: center !important;
    width: 110px;
    margin: 25px auto;
}
.form-wizard .progress-bar {
    background-color: #999;
}

.form-wizard-steps{
    margin:auto;
    overflow: hidden;
    position: relative;
}

.form-wizard-progress {
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ddd;
}
.form-wizard-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    background: #999;
}

.form-wizard-tolal-steps-3 .form-wizard-step {
    position: relative;
    float: left;
    width: 33.33%;
    padding: 0 5px;
}
.form-wizard-tolal-steps-4 .form-wizard-step {
    position: relative;
    float: left;
    width: 25%;
    padding: 0 5px;
}
.form-wizard-tolal-steps-5 .form-wizard-step {
    position: relative;
    float: left;
    width: 20%;
    padding: 0 5px;
}

.form-wizard fieldset {
    display: none;
    text-align: left;
    border:0px !important
}

.form-wizard-buttons {
    text-align: right;
    margin-top: 30px;
    border-top: solid 1px #ccc;
    padding-top: 30px;
}

.form-wizard .input-error { border-color: #e53935;}

/** image uploader **/
.image-upload a[data-action] {
  cursor: pointer;
  color: #555;
  font-size: 18px;
  line-height: 24px;
  transition: color 0.2s;
}
.image-upload a[data-action] i {
  width: 1.25em;
  text-align: center;
}
.image-upload a[data-action]:hover {
  color: #fd423f;
}
.image-upload a[data-action].disabled {
  opacity: 0.35;
  cursor: default;
}
.image-upload a[data-action].disabled:hover {
  color: #555;
}
.settings_wrap{
    margin-top:20px;
}
.image_picker .settings_wrap {
  overflow: hidden;
  position: relative;
}
.image_picker .settings_wrap .drop_target,
.image_picker .settings_wrap .settings_actions {
  float: left;
}
.image_picker .settings_wrap .drop_target {
  margin-right: 18px;
}
.image_picker .settings_wrap .settings_actions {
    float: left;
    margin-top: 100px;
    margin-left: 20px;
}
.settings_actions.vertical a {
  display: block;
}
.drop_target {
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
    width: 250px;
    height: 250px;
    background: #f2f2f2;
    border-radius: 100%;
    margin: 0 auto 25px auto;
    overflow: hidden;
    border: 8px solid #E0E0E0;
}
.drop_target input[type="file"] {
  visibility: hidden;
}
.drop_target::before {
    content: 'Drop Hear';
    font-family: FontAwesome;
    position: absolute;
    display: block;
    width: 100%;
    line-height: 220px;
    text-align: center;
    font-size: 40px;
    color: rgba(0, 0, 0, 0.3);
    transition: color 0.2s;
}
.drop_target:hover,
.drop_target.dropping {
  background: #f80;
  border-top-color: #cc6d00;
}
.drop_target:hover:before,
.drop_target.dropping:before {
  color: rgba(0, 0, 0, 0.6);
}
.drop_target .image_preview {
  width: 100%;
  height: 100%;
  background: no-repeat center;
  background-size: contain;
  position: relative;
  z-index: 2;
}

.form-box h6 { color: #f1f1f1; font-weight: 200; text-align: center; margin: 0 0 40px 0; font-size:20px;  }
.form-box h6 strong { font-weight: 400; }

.form-body-classic textarea.form-control { min-height: 80px; }
.form-body-classic .form-control:focus { border-color: #2e2e2e; }
.form-body-classic .form-group:focus~label { color: #fd423f; }
.btn-toggle .addiction { display: none; }
.form-group { display: table; width: 100%; }


.form-body-classic .form-control:focus + label { color: #2e2e2e; }
.patient-addictions .form-group .form-control { display: none; }
.patient-addictions .form-group .btn-toggle:focus + .form-control { display: block; margin-top: 10px; }
.form-wizard-step p {
    text-align: center;
}
/*Wizard End*/
.btn {
  border-radius: 0px;
}
.profile-info a.dropdown-toggle {
    display: block;
    font-style: var(--medium-font-size);
    padding: 5px 0px;
}
li.profile-info .caret {
    display: none;
}
.profile-info a.dropdown-toggle:focus, .profile-info a.dropdown-toggle:hover {
  color: var(--ex-dark-color);
}
#doctor-profile-menu:hover, #doctor-profile-menu:focus, .profile-info.open a {
  background:none;
  color: var(--dark-color);
}
.profile-info.dropdown .dropdown-menu li, .more-option.nav > li.open > ul.dropdown-menu li a {
    color: var(--dark-color);
    background: var(--ex-light-color);
    padding: 12px;
    font-size: 16px;
    /*border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;*/
    border-bottom: solid 1px var(--light-color);
}

.more-option.nav > li.open > ul.dropdown-menu  {
  margin: 10px 0 0;
  padding: 0px;
  border: 0px;
}
.profile-info.dropdown .dropdown-menu li:last-child a { border-bottom: 0px; }
.profile-info .dropdown-menu {
  margin: 9px 0 0;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
}
.status {background: none; padding: 3px; border: solid 1px; }
.status.green { color: var(--theme-bg-color); border-color: var(--theme-bg-color); }
.status.yellow { color: var(--yellow); border-color: var(--yellow); }
.margin-bottom-20 {
  margin-bottom: 20px;
}

/*Side menu*/
.page-container .sidebar-menu { background: var(--clear-menu-bg-color);
 /* box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .1);
  -ms-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .1);*/
  display: table-cell;
    vertical-align: top;
    width: 230px;
    position: relative;
    z-index: 100;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
 }
 .page-container .sidebar-menu .logo-env > div > a {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}
/*End side menu*/
.page-container .sidebar-menu.fixed .sidebar-menu-inner { top:0; }
.main-content.right-sidebar-remove { padding: 0px; }
.page-container .sidebar-menu .logo-env > div.sidebar-collapse, .page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu { width: 15%; }
.page-container .sidebar-menu .logo-env > div { width: 80%; float: left;}
.page-container .sidebar-menu .logo-env > div.sidebar-collapse a  { padding: 5px 10px; border: none; }
.page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu a { padding: 5px 0; }
.sidebar-collapse a svg { color: var(--clear-menu-bg-color); }
.main-menu svg {
    margin: 3px 10px 0 0;
    float: left;
}
.sidebar-collapsed svg {
  display: inline-block;
  /* float: inherit; */
}
.page-container .sidebar-menu .logo-env {
    padding:1px 5px;
    background: var(--theme-bg-color);
    overflow: hidden;
    clear: both;
}
.page-container .sidebar-menu #main-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.page-container .sidebar-menu #main-menu li a {
    color: var(--ex-dark-color);
    font-weight: 400;
    font-size: 14px;
    position: relative;
    display: block;
    padding: 14px 20px;
    /* transition: color 250ms ease-in-out background-color 250ms ease-in-out; */
}
.page-container .sidebar-menu #main-menu li a span{
  font-size: 14px;
}
.page-container .sidebar-menu #main-menu li a svg {
  color: var(--ex-dark-color);
}
.tile-stats h3 { font-size: 18px; }
.dashboard {
    overflow: hidden;
    clear: both;
    padding: 10px;
    margin: 10px 0;
    float: left;
    width: 100%;
}
.tile-stats p {
  font-size:
}
.tile-stats { cursor: pointer; }
.tile-stats:hover {
  background: #71D037;
}
.tile-stats.green {
  background: var(--theme-bg-color);
}
.approved, .disapproved { padding:3px 5px; border: solid 1px; border-radius: 0px; }
.approved { color: var(--theme-bg-color); border-color:var(--theme-bg-color);  }
.disapproved { border-color: var(--yellow); color: var(--yellow); }
.margin-top-10 {
    margin-top: 10px;
    display: block;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { color: var(--ex-dark-color) }
.modal-backdrop { z-index: 9999; }
.modal { z-index: 99999; }
div.datepicker {
    padding: 4px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    direction: ltr;
}
div.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}
div.datepicker-dropdown.datepicker-orient-top:before {
    top: -7px;
}
div.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ebebeb;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}
div.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
div.datepicker thead tr:first-child th, div.datepicker tfoot tr th {
    cursor: pointer;
}
.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
    padding: 5px;
}
div.datepicker td, div.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
}
div.datepicker table tr td.day:hover {
    background: #eeeeee;
    cursor: pointer;
    color: #303641;
    -moz-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
div.datepicker table tr td.old, div.datepicker table tr td.new {
    color: #999999;
}
div.datepicker table tr td.active, div.datepicker table tr td.active:hover, div.datepicker table tr td.active.disabled, div.datepicker table tr td.active.disabled:hover {
    background: #303641;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
div.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}
div.datepicker-dropdown.datepicker-orient-top:after {
    top: -6px;
}
div.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #FFFFFF;
    border-top: 0;
    position: absolute;
}
div.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}
div.datepicker-dropdown.datepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #CCC;
}
div.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ebebeb;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}
div.datepicker-dropdown.datepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #FFFFFF;
}
div.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}
div.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #FFFFFF;
    border-top: 0;
    position: absolute;
}
.page-body .select2-container.select2-dropdown-open .select2-choice, .page-body .select2-container.select2-dropdown-open .select2-choices {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}
.page-body .select2-container .select2-choice {
    /*border-color: #ebebeb !important;*/
    border: 0px;
    background: #fff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    height: 38px;
    line-height: 38px;
    outline: none;
    padding-left: 15px;
}
.page-body .select2.visible {
    visibility: visible;
}
.page-body .select2-container {
    display: block;
}
.page-body .select2-drop {
    border: 1px solid #282828;
    background-color: #fff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-top: 0px;
}
.page-body .select2-drop .select2-search {
    position: relative;
    padding: 12px 15px;
}
.page-body .select2-drop .select2-search, .page-body .select2-drop .select2-results {
    padding: 0;
    margin: 0;
}
.page-body .select2-drop .select2-search {
    padding: 10px;
    margin: 0;
}
.page-body .select2-container .select2-choice .select2-search-choice-close {
    right: 50px;
    top: 50%;
    margin-top: -7px;
}
.page-body .select2-container .select2-choice .select2-arrow {
    border-left: 0px;
    width: 40px;
    text-align: center;
    background: none !important;
    -moz-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.page-body .select2-container .select2-choice .select2-arrow b {
    background: transparent !important;
}
.page-body .select2-container .select2-choice .select2-arrow b {
    background: transparent !important;
}
.page-body .select2-container .select2-choice .select2-arrow b:before {
    position: relative;
    font-family: "FontAwesome";
    content: "\f107";
    display: block;
    color: #303641;
    -moz-transition: all 150ms ease-in-out;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.page-body .select2-container.select2-dropdown-open .select2-choice .select2-arrow b:before {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.down {
position: relative;
    font-family: "FontAwesome";
    font-style: normal;
    display: inline-block;
    color: #303641;
    -moz-transition: all 150ms ease-in-out;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.down:before {
  content: "\f107";
}
.open .down {
  -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.select2-results .select2-highlighted {
    background: #eee;
    color: #282828;
}
.form-control.select2-container {
    height: 40px !important;
    padding: 0;
    margin-top: 5px;
}
.month-name {
    font-size: var(--medium-font-size);
    color: var(--ex-dark-color);
    margin: 0 10px;
    line-height: 23px;
}
.change-months {
  position: relative;
  float: right;
  right: 20px;
}
.appointment-calendar .previous, .appointment-calendar .next {
    color: var(--ex-dark-color);
    position: absolute;
    top: 0;
    font-family: "FontAwesome";
    font-size: 22px;
    cursor: pointer;
    line-height: 22px;
}
.appointment-calendar .previous {
  left: -10px;
}
.appointment-calendar .next {
  right: -10px;
}
.previous:before {
  content: "\f104"
}
.next:before {
  content: "\f105";
}
.date-columns > ul {
    float: left;
    width: 12.50%;
    margin: 0px;
}
.date-columns {
    margin-top: 10px;
    border: solid 2px #999;
}
.date-columns:after{
  content: "";
    display: block;
    clear: both;
}
.time-column > li:first-child, .single-date-column > li:first-child {
  background: var(--light-color);
  color: var(--clear-menu-bg-color);
  text-align: center;
  margin-right: 1px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 10px 0;
  letter-spacing: 1px;
  font-size: var(--medium-font-size);
}
.single-date-column > li:not(:first-child), .time-column > li:not(:first-child) {
  height: 180px;
}
.time-column > li:not(:first-child) {
    border-bottom: solid 1px var(--light-color);
    background: rgba(0,0,0,0.05);
    color: var(--dark-color);
    text-transform: capitalize;
    padding: 0px 15px;
    font-weight: 500;
    font-size: var(--medium-font-size);
    vertical-align: middle;
    border-right: solid 1px var(--light-color);
    display: flex;
    align-items: center;
}
.time-column > li:last-child {
  border-bottom: none;
}
.single-date-column li ul li {
    height: 15px;
    background: var(--clear-menu-bg-color);
    border-bottom: solid 1px var(--page-bg-color);
    border-right: solid 1px var(--light-color);
    position: relative;
}
.single-date-column li ul li:last-child {
    border-bottom: solid 1px var(--light-color);
}
.single-date-column li:last-child ul li:last-child{
  border-bottom: 0px;
}
.single-date-column:last-child li ul li {
  border-right: 0px;
}
.current-appointments h3 {
    text-align: left;
    text-transform: capitalize;
    font-weight: 200;
    font-size: 20px;
    padding: 10px;
    margin: 0px;
}
.current-appointments h3 span {
  font-weight: 500;
}
.patient-img {
    height: 40px;
    width: 40px;
    float: left;
    margin-right: 10px;
}
.patient-img img {
    border-radius: 50%;
  border: solid 1px #999;
  width: 100%;
  max-width: 100%;
}
.patient-name {
    float: left;
    width: 57%;
    font-size: var(--medium-font-size);
    font-weight: 400;
    color: var(--dark-color);
}
.patient-name span {
  width: 100%;
  display: block;
  font-size: var(--default-font-size);
  font-weight: 200;
}
.more-option {
    float: right;
    margin-top: 11px;
    cursor: pointer;
    padding: 0 5px;
}
.more-option li a {
    padding: 0;
}
.more-option li a:focus, .more-option li a:hover, .more-option.nav li.open a {
  background: none;
  color: var(--ex-dark-color);
}
.register-appointment-list {
    /*margin: 0 -15px;*/
    padding: 15px 10px;
    border-bottom: solid 1px #ddd;
}
.register-appointment-list:after {
  clear: both;
  content: '';
  display: block;
}
.register-appointment-list:last-child {
  border-bottom: 0px;
}
.more-option > .dropdown-menu {
    right: -6px;
    left: inherit;
    margin-top: 10px;
}
.more-option.nav .open .dropdown-menu:before {
    top: -16px;
    right: 9px;
    left: auto;
    border: 8px solid transparent;
    border-bottom-color: var(--ex-light-color);
    position: absolute;
    content: '';
    display: inline-block;
}
.more-option > .dropdown-menu li:last-child a {
  border-bottom: 0px;
}
.more-option > .dropdown-menu li a:hover {
  background: var(--side-menu-border);
}
.more-option > a svg {
  color: var(--light-color);
}
.single-appointment > .dropdown-toggle {
    overflow: hidden;
    position: absolute;
    font-size: 12px;
    padding: 1px;
    width: 100%;
    text-align: center;
    line-height: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.appointment-view-details {
  min-width: 290px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px var(--dark-color);
  -moz-box-shadow: 3px 3px 3px #ccc;
-webkit-box-shadow: 3px 3px 3px #ccc;
box-shadow: 3px 3px 3px #ccc;
text-align: center;
top: -60px;
left: 90%;
}
.appointment-view-details .profile-img {
  text-align: center;
  margin: 10px 0;
}
.appointment-view-details .profile-img img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}
.single-appointment .patient-name {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--ex-dark-color);
  text-align: center;
}
.single-appointment .patient-others {
    clear: both;
    font-size: var(--medium-font-size);
    font-weight: 200;
    color: var(--dark-color);
    margin: 5px 0;
    display: inline-block;
    padding-bottom: 5px;
    border-bottom: solid 1px var(--light-color);
    width: 100%;
}
.appointment-schedule {
    text-align: left;
    font-size: var(--medium-font-size);
    font-weight: 200;
    color: var(--dark-color);
    margin: 5px 0 10px 0;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--light-color);
}
.appointment-schedule > span {
  font-weight: 400;
  color: var(--ex-dark-color);
}
.time, .file, .cross {
  font-style: normal;
  color:var(--dark-color);
  position: relative;
  font-family: 'FontAwesome';
  font-size: 20px;
}
.time:before {
  content: "\f017";
}
.file:before {
content: "\f0f6";
}
.cross:before {
  content: "\f057";
}
.appointment-view-details {
    padding: 10px;
    margin: 1em 0 3em;
    border: 1px solid #999;
    color: #333;
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.appointment-view-details:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 40px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #999 transparent;
    display: block;
    width: 0;
}
.appointment-view-details.left {
    margin-left: 30px;
}
.appointment-view-details.left:before {
    top: 47px;
    bottom: auto;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #999;
}
.appointment-view-details:after {
    content: "";
    position: absolute;
    bottom: -13px;
    left: 47px;
    border-width: 13px 13px 0;
    border-style: solid;
    border-color: #fff transparent;
    display: block;
    width: 0;
}
.appointment-view-details.left:after {
    top: 47px;
    bottom: auto;
    left: -13px;
    border-width: 10px 15px 10px 0px;
    border-color: transparent #fff;
}
.appointment-view-details > h3 {
    margin: -10px -10px 10px;
    border-bottom: solid 1px #999;
    padding: 10px 0;
    text-align: center;
    background: #eee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.click-add-appointment .appointment-view-details {
  text-align: left;
}
.add-patient .form-group {
    width: 90%;
    float: left;
}
.add-patient-button {
    float: right;
    width: 10%;
    margin-top: 25px;
    background: #999;
    height: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.appointment-view-details .add-patient-button {
  height: 30px;
}
.add-patient-button svg {
    width: 100% !important;
    color: #fff;
    margin: 10px 0 5px -8px;
}
.appointment-view-details .add-patient-button svg {
    margin: 6px 0 5px -9px;
}
.add-patient-button .btn {
    padding: 0px;
}
.appointment-view-details .add-patient .selectboxit-container .selectboxit {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.click-add-appointment .btn.text-btn {
  width: 100%;
}
.click-add-appointment a.dropdown-toggle {
    font-size: 13px;
    opacity: 0;
    width: 100%;
    display: block;
}
.doctor-profile-img img {
    max-width: 100%;
    border: solid 1px #999;
    height: 200px;
    width: 200px;
    border-radius: 50%;
}
.avatar { text-align: center; }
.avatar:hover { cursor: pointer; }

.profile-edit-img {
    /*width: 100%;
    margin: 0 -15px -15px;
    display: block;*/
}

.profile-edit-img .icon-btn {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 10px;
}
.doctor-profile-img h3 {
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    margin: 20px 0 0;
    color: var(--ex-dark-color)
}
.doctor-profile-img h4 {
  text-align: center;
  content: var(--dark-color);
  font-size: var(--medium-font-size);
  font-weight: 400;
  margin: 10px 0;
}
.doctor-profile-img h5 {
  text-align: center;
  color: var(--dark-color);
  font-size: var(--default-font-size);
  border-bottom: solid 1px var(--theme-bg-color);
  padding-bottom: 20px;
  font-weight: 400;
}
.doctor-profile-img p {
  font-size: var(--medium-font-size);
  border-bottom: solid 1px var(--theme-bg-color);
  padding: 10px 0 20px;
}
.specialisation-section .row {
    background: var(--light-color);
    margin-top: -15px;
    margin-bottom: 10px;
}
.specialisation-section > .row > h3, .specialisation-section > .row > a {
    color: var(--clear-menu-bg-color);
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    font-weight: 400;
    padding: 10px 15px;
}
.specialisation-section {
  background: var(--clear-menu-bg-color);
  margin-bottom: 0px;
}
.specialisation-section tr td {
  color: var(--dark-color);
  font-size: var(--medium-font-size);
}
.specialisation-section table a {
    color: #fff;
    margin: 0px;
    opacity: 0;
}
.specialisation-section table tr:hover a {
    opacity: 1;
}
.specialisation-section .table td tr:nth-of-type(odd), .specialisation-section .table td tr:hover {
  background-color: var(--ex-light-color)
}
.ReactTable.table {
  margin-bottom: 0px;
  border:0px;
}
.specialisation-section thead:first-child > tr:first-child > th {
  font-weight: 500;
  border-top: 0px !important;
  font-size: var(--medium-font-size);
  color: var(--ex-dark-color);
}
.portfolio-image img {max-width: 100%;}
.portfolio-image img:hover { cursor: pointer; }
.portfolio-image {
    text-align: center;
    background: none;
    padding: 5px;
    border: solid 1px;
    background: none;
}
.row.remove-bg {
    background: none;
    clear: both;
    overflow: hidden;
    margin: 20px 0;
}
.portfolio-image .btn {
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0;
}
.portfolio-image:hover .btn {
  opacity: 1;
}
.specialisation-section .col-md-3 {
  margin-bottom: 10px;
}
.social-media, .profile-edit-img {
    margin-top: 5px;
}
.social-media .btn {
    padding: 5px;
    height: 30px;
    width: 30px;
}
.social-media .btn:last-child {
    padding: 7px 4px 10px;
}
i.google-plus {
    font-size: 16px;
    padding: 0;
    margin: -10px 0;
}
.facebook,
.twitter,
.linkedin,
.google-plus {
  font-family: FontAwesome;
  font-size: 20px;
  display: inline-block;
  font-style: normal;
  line-height: 19px;
}

.facebook:before {
  content: "\f09a";
}
.twitter:before {
  content: "\f099";
}
.linkedin:before {
  content: "\f0e1";
}
.google-plus:before {
  content: "\f0d5";
}
img.profile-image {
    max-width: 100%;
    border: dashed 2px;
}
.modal-footer {
  padding: 15px 15px;
}
.add-btn, .add-more {
    display: inline-block;
    color: #fff;
    margin-top: 10px;
    border: dashed 1px;
    padding: 0 5px;
    float: right;
}
.add-more {
  color: #999;
  background: none;
}
.add-btn:hover, .add-btn:focus {
  color: #ddd;
}
.page-header {
    padding-bottom: 15px;
    margin: 0 -15px 15px;
    border-bottom: 1px solid #eeeeee;
}
.page-header h2 {
    margin: 8px 0 5px;
}
.table-condensed .next:before {
  display: none;
}
.table-condensed thead:first-child > tr:first-child > th {
  border: 0px !important;
}
.appointment-calendar {
    margin-top: -5px;
}
.from-heading h3 {
    margin: 0;
    color: #999;
    font-size: 14px;
    position: relative;
}
.from-heading h3:after {
    position: absolute;
    content: "";
    height: 1px;
    background: #ddd;
    width: 90%;
    top: 7px;
}
.page-container .sidebar-menu #main-menu li.active {
  border-left: solid 3px var(--theme-bg-color);
}
.appointment-view-details .form-control {
    height: 30px;
}
.appointment-view-details .form-group {
  margin-bottom: 5px;
}
.appointment-view-details .selectboxit-container span,
.appointment-view-details .selectboxit-container .selectboxit-options a {
  line-height: 30px;
}
.appointment-view-details textarea {
  height: 50px !important;
  margin-bottom: 10px;
}
.rrp {
    padding-right: 0;
}
.appointment-calendar h2 {
  font-size: 20px;
}
.table-img-profile {
    float: left;
    margin-right: 10px;
    width: 44px;
}
.table-img-profile img {
    width: 44px;
    height: 44px;
}
.table-details {
    float: left;
    font-size: var(--medium-font-size);
    text-transform: capitalize;
    color: var(--ex-dark-color);
    line-height: 1;
    font-weight: 500;
}
.table-details span {
    font-size: 14px;
    margin-top: 10px;
    display: block;
    font-weight: 400;
    color: var(--dark-color);
}
.search-section .form-control, .search-section .selectboxit-container {
  margin-top: 0px;
}
.search-section .form-group {
    position: relative;
}
.search-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.nav-tabs > li > a {
    background: #eee;
    padding: 6px 15px;
    border-radius: 0px;
    color: var(--ex-dark-color);
    font-weight: 500;
    font-size: 15px;
}
.nav-tabs > li.active > a {
  border-color: #eee;
  border-bottom-color:transparent;
}
.tab-content > .active {
    border: solid 1px var(--side-menu-border);
    clear: both;
    float: left;
    width: 100%;
    padding-top: 20px;

}
.tab-content.left-tabs > .active {
  min-height: 410px;
  padding: 15px;
}
.patient-medical-profile .tab-content.left-tabs > .active {
  min-height: 522px;
}
.margin-top-25 {
  margin-top: 25px;
}
.btn + .btn {
  margin-left: 5px;
}
.nav-tabs.tabs-left li {
    display: inline-block;
    float: none;
    width: 100%;
}
.nav-tabs.tabs-left li a {
  margin-right: 0px;
  margin-bottom: 5px;
  background: #f4f4f4;
  padding: 10px 15px;
  color: var(--dark-color);
  font-size: var(--default-font-size);
  font-weight: 400;
}
.nav-tabs.tabs-left li.active a {
    border-bottom-color: var(--side-menu-border);
    border-top-color: var(--side-menu-border);
    border-left-color: var(--side-menu-border);
    border-right-color: var(--clear-menu-bg-color);
    margin-right: -1px;
    z-index: 1;
    background: var(--clear-menu-bg-color);
    font-weight: 500;
    color: var(--ex-dark-color);
}
.nav-tabs > li > a:hover {
  border-color:transparent;
}
.nav-tabs.tabs-left {
  margin-top: 0px;
}
.rlp {
    padding-left: 0;
}
.tabs-left span {
    width: 20px;
    display: inline-block;
    color: var(--dark-color);
}
.tabs-left .active span, .tabs-left li.active a {
  color: var(--ex-dark-color);
  font-weight: 400;
}

.page-header.new-visit {
    padding: 0;
    border: 0;
    margin: 0 -15px 0;
}
.patient-profile-img {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 15px;
    border: solid 2px #999;
}
.patient-profile-img img {
    max-width: 100%;
}
.patient-details-wrap {
  line-height: 25px;
    float: left;
}
.patient-name-code {
    font-size: 20px;
    color: var(--ex-dark-color);
    font-weight: 500;
}
.patient-name-code > span {
    font-size: var(--medium-font-size);
    font-weight: 400;
    display: block;
    color: var(--dark-color);
}
.patient-name-code .title {
    font-weight: 200;
    font-size: 20px;
    display: inline-block;
    color: var(--ex-dark-color);
}
.patient-other-details {
    font-size: var(--default-font-size);
    color: var(--dark-color);
}
.patient-other-details svg.fa-mobile-alt {
    margin-left: 0;
}
.patient-show-status {
    padding: 0 3px;
    margin: 5px 13px 0;
    display: inline-block;
    font-weight: 600;
}
.patient-info {
    display: inline-block;
    margin-right: 20px;
}
.visit-info {
    display: inline-block;
    vertical-align: top;
    border-left: solid 1px var(--side-menu-border);
    padding-left: 20px;
}
.patient-other-details svg {
    font-size: 13px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin: 0 3px;
}
.visit-number, .visit-status {
    font-size: 16px;
    color: var(--dark-color);
    font-weight: 400;
    margin: 3px 0;
}
.visit-number span {
    margin: 0 5px;
    font-weight: 600;
    color: var(--ex-dark-color);
}
.other-potions {
    float: right;
    margin: 0;
    margin-left: 5px;
    border: solid 1px #999;
    font-size: var(--medium-font-size);
    text-transform: capitalize;
}
.other-potions > li > a {
    padding: 7px 10px;
    display: block;
}
.other-potions .dropdown-menu {
    left: inherit;
    right: 0px;
    border-radius: 0px;
}
.other-potions .dropdown-menu > li > a {
    padding: 5px 10px;
    font-size: var(--default-font-size);
}
.other-potions .dropdown-menu > li > a span {
  width: 20px;
  display: inline-block;
}
.left-tabs .tab-pane h4 {
    background: #eee;
    padding: 13px 15px;
    margin: -15px -15px 15px;
}
.visit-tabs {
    width: 18%;
    float: left;
    padding-left: 15px;
}
.visit-tabs.patient-medical-profile, .visit-tabs.new-visit  {
    width: 22%;
}
.visit-tabs-contents {
  width: 82%;
  float:left;
  padding-right: 15px
}
.visit-tabs-contents.new-visit {
  width: 78%;
}
.patient-medical-profile.visit-tabs-contents {
    width: 78%;
}
.left-tabs .tab-pane h5 {
    font-size: var(--default-font-size);
}
.header-action, .discontinue-option {
    display: inline-block;
    vertical-align: middle;
}
#r-investigations .checkbox-section.single-line label {
    display: block;
    text-align: left;
    float: left;
    clear: both;
}
.view-value {
    font-weight: 500;
    color: #282828;
    margin: 3px 0 0;
    padding: 12px 10px;
    background: #f1f1f1;
    cursor: default;
    line-height: 20px;
}
label.view-label {
    font-weight: 400;
    cursor: default;
}
.image-view {
    border: solid 3px #eee;
    margin-top: 3px;
}
.image-view img {
  max-width: 100%;
  width: 100%;
}
.table-dropdown {
    margin: 0;
    border: dashed 1px;
    padding: 2px 7px;
    display: inline-block;
}
.table-dropdown .dropdown-menu{
    left: inherit;
    right: 0;
}
a.btn {
    z-index: 9;
}
#view-prescription .modal-dialog {
  width: 900px;
}
.doctor-logo {
    float: left;
    margin-right: 10px;
    width: 50px;
}
.doctor-logo img {
    width: 100%;
}
.doctor-info {
  float: left;
}
.doctor-info h4 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
}
.doctor-info h5 {
    font-size: 15px;
    font-weight: normal;
}
.doctor-other-info {
    text-align: right;
    color: #282828;
}
.prescription-dt-time {
    color: #5e5e5e;
    font-size: 16px;
}
.bmi {
  margin: 20px 0;
}
.bmi span {
    display: inline-block;
    color: #282828;
    width: 13%;
}
.sections h3 {
    margin: 0;
    font-size: 16px;
}
.sections ul li {
  list-style-type: disc;
  font-size: 16px;
  color: #282828;
}
#view-prescription .medicine-detail-heading h5 {
font-size: 16px;
}
#view-prescription .medicine-detail {
  padding: 0px;
  color: #282828;
  border: solid 1px #999;
}
#view-prescription .discontinue-option {
  font-size: 16px;
  margin: 6px 0;
  color: #282828
}
#view-prescription .medicine-details-inner {
  color: #282828;
}
span.view-prescription-action > ul {
    float: right;
    border: dashed 1px #282828;
    padding: 1px 6px;
    margin: 0 15px 0;
}
.view-prescription-action .dropdown-menu {
  left: inherit;
  right: -9px;
}
.view-prescription-action .dropdown-menu > li > a {
    color: #5e5e5e;
    font-size: 14px;
    padding: 5px 10px;
}
.custom-select .Select-control {
    height: 40px;
    border-color: var(--light-color);
    color: var(--ex-dark-color);
    font-size: var(--medium-font-size);
    margin-top: 5px;
    border-radius: 0px;
    -webkit-appearance: none;
}
.custom-select.Select.is-focused:not(.is-open) > .Select-control{
  border-color: var(--ex-dark-color);
  box-shadow: none;
}
.custom-select.Select.is-open .Select-control, .custom-select.Select.is-open > .Select-menu-outer {
  border-color: var(--ex-dark-color);
  box-shadow: none;
  border-radius: 0px;
  -webkit-border-radius: 0px;
}
.Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 40px !important;
}
.modal .modal-header .close {
    background: none;
    display: inline-block;
    padding: 0px 5px;
    line-height: 1;
    color: #282826;
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    text-shadow: none;
    font-weight: 400;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    border: solid 1px;
    height: 25px;
    width: 25px;
    line-height: 15px;
}
.nav-tabs {
  border-bottom: 0px;
}
.user-info.nav li a {
    display: inline-block;
    padding: 0;
}
section {
  display: block;
  height: 100%;
}

.sh-hidden, .Select-clear-zone { display:none; }
.rpr {
    padding-right: 0px;
}
.rpl {
    padding-left: 0px;
}
.country-code .Select-control {
    border-right: 0px;
}
.help-block {
    font-size: 12px;
    margin: 3px 0px 0;
}
.has-error .custom-select .Select-control {
    border-color: #ac1818;
}
.media-thumbnail  {
    height:250px;
    width:100%;
}
.thumb-img {
    margin-top: 10px;
}
.portfolio-image {
    text-align: center;
}
.drop_box {
    border: 2px dashed #d2d2d2;
    position: relative;
    height:260px;
    width: 260px;
    /*min-height: 350px;
    min-width: 250px;
    max-width: 1000px;
    margin: auto;*/
    display: flex;
    padding: 0;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
    color: #999;
    justify-content: center;
    border-radius: 3px;

}
.drop_box p {
    font-size: 24px;
    padding: 10px;
    display: block;
}
.drop_box .select-upload {
    cursor: pointer;
    font-size: var(--medium-font-size);
    padding: 8px 15px;
    min-width: 100px;
    display: inline-block;
}
.progress {
    box-shadow: none;
    margin: 10px 0;
    background: #e9ecef;
    border-radius: 20px;
    padding: 3px;
}
.progress-bar {
    /*box-shadow: 0 5px 5px rgba(255, 255, 255, .7) inset, 0 -5px 5px rgba(255, 255, 255, .7) inset;*/
    /*animation: animate-glow 1s ease-out infinite;*/
    background: var(--theme-bg-color);
    border-radius: 20px;
    color: #fff;
    line-height: 14px;
    font-weight: bold;
}
.img-thumbnail {
    height: 90px;
    padding: 0px;
}
.single-img {
    position: relative;
    margin: 5px;
}
.overlay {
    background: rgba(0,0,0,0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
}
.single-img .progress {
    margin: 40px 10px;
}
.upload-icon svg {
    font-size: 60px;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}
.react-confirm-alert-button-group {
  justify-content: center !important;
}
.react-confirm-alert-body, .react-confirm-alert-button-group > button {
  text-align: center;
  border-radius: 0px;
}
.react-confirm-alert-body h1 {
  text-transform: capitalize;
}
.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 60px;
}
.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #56AF43;
  }
  40% {
    stroke: #F9AA25;
  }
  66% {
    stroke: #999;
  }
  80%,
  90% {
    stroke: #fff;
  }
}

.dataCellClass,.rt-th{
  padding: 8px !important;
  text-align: left;
  color: #000;
  vertical-align: middle;
}
.actionCellClass{
  text-align: center;
}

.rt-th,.rt-td{
  font-weight: bold;
  border: 1px solid #ebebeb;
  border-bottom : none;
  border-right: none;
}
.ReactTable .rt-thead.-header{
  box-shadow:none !important;
}
.timing-table td {
  vertical-align: middle !important;
}
.timingCell{
  font-size: 14px !important;
}
.ReactTable .-pagination {
  justify-content: flex-end !important;
}
.-pagination .-previous, .-pagination .-center, .-pagination .-next {
  flex: inherit !important;
}
.table-wrap {
  position: relative;
}
.table-wrap .table-search {
    position: absolute;
    padding: 0 10px;
    z-index: 99;
    top: 3px;
}
.table-search-input, .-pageJump input, .select-wrap.-pageSizeOptions select {
  border: solid 1px var(--light-color) !important;
  padding: 5px;
  border-radius: 0px !important;
}
.table-search-input:focus, .-pageJump input:focus, .select-wrap.-pageSizeOptions select:focus {
  border: solid 1px var(--ex-dark-color) !important;
}
.rt-thead.-filters {
  display: none !important;
}
td.inner-edit-button {
    position: relative;

}
td.inner-edit-button a {
    color: var(--clear-menu-bg-color);
    z-index: 9999;
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-top: 0px !important;
  padding: 0 0px 10px 5px !important;
}
.specialisation-section table tr:hover td.inner-edit-button a {
  opacity: 0;
}


.rt-tr-group:last-child .rt-tr .rt-td {
  border-bottom: solid 1px var(--table-border);
}
.ReactTable .rt-tbody .rt-td:last-child, .ReactTable .rt-thead .rt-th:last-child {
  border-right: solid 1px var(--table-border) !important;
}
.inner-edit-button span {
    font-size: var(--default-font-size);
    font-weight: 400;
    white-space: nowrap;
    color: var(--ex-dark-color);
}
.inner-edit-button span.clinic-name {
    font-size: var(--default-font-size);
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: #999;
    font-weight: 400;
}
.ReactTable .rt-noData {
  position: static !important;
  transform:inherit !important;
  -webkit-transform:inherit !important;
  text-align: center;
  z-index: 0 !important;
  border-top: solid 1px var(--table-border);
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: 0px !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  position: relative;
}
.ReactTable .rt-thead .rt-th.-sort-desc:after, .ReactTable .rt-thead .rt-th.-sort-asc:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.ReactTable .rt-thead .rt-th.-sort-desc:after {
  border-top: 5px solid #2f2f2f;
}
.ReactTable .rt-thead .rt-th.-sort-asc:after {
  border-bottom: 5px solid #2f2f2f;
}
.inner-edit-button span {
    font-size: var(--default-font-size);
    font-weight: 400;
    white-space: nowrap;
    color: var(--ex-dark-color);
}
.inner-edit-button span.clinic-name {
    font-size: var(--default-font-size);
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    color: #999;
    font-weight: 400;
}
/*==================================== BookingAppointments ====================================*/
.doctor-details {
    background: #fff;
    padding: 30px;
}
.doctor-name {
    font-size: 36px;
    font-weight: 600;
    color:#26763A;
}
.doctor-profile-image img {
    border: solid 3px #ccc;
    height: 150px;
    width: 150px;
    margin-bottom: 10px;
}
.doctor-specialties {
    font-size: 22px;
    margin: 0px 0 10px;
    font-weight: 200;
    color:var(--dark-grey);
}
.review a {
    color: #bbb;
    font-size: 18px;
    margin: 0 5px;
}
.rating-stars ul {
  list-style-type:none;
  padding:0;
  -moz-user-select:none;
  -webkit-user-select:none;
}
.rating-stars ul > li.star {
  display:inline-block;

}

/* Idle State of the stars */
.rating-stars ul > li.star > svg {
  font-size:1.5em; /* Change the size of the stars */
  color:#89C53E ; /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > svg {
  color:#FFCC36;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > svg {
  color:#FF912C;
}
#doctor-details {
  margin: 10px 0;
}
#doctor-details .sections {
  margin-bottom: 40px;
}
#doctor-details .nav-tabs > li > a:focus {
  outline: none;
  outline-offset: 0px;
}
#doctor-details .nav-tabs > li > a {
  color: var(--dark-grey);
  background: none;
  padding: 10px 35px;
  font-weight: 600;
  border: none;
  margin: 0px;
}
#doctor-details .nav-tabs > li.active > a, #doctor-details .tab-content > .active {
  background: var(--clear-menu-bg-color);
  border: none;
}
#doctor-details .tab-content > .active {
  padding: 40px 20px 20px;
}
#doctor-details .sections h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #26763A;
}
#doctor-details .sections p {
  font-size: 16px;
  color:var(--dark-grey);
  margin-bottom: 10px;
  font-weight: normal;
}
#doctor-details .sections.gallery img {
  width: 100%;
}
.book-appointment h2 {
  background: #26763A ;
  color: var(--clear-menu-bg-color);
  padding: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}
.appointment-details {
    background: var(--clear-menu-bg-color);
    margin-top: 0px;
    padding: 1px 20px 20px;
}
.appointment-details h3 {
  margin: 0px 0 10px;
  color: var(--dark-grey);
  font-size: 16px;
  max-height: 400px;
}
.ratings {
    display: inline-block;
    text-align: center;
    margin: 0 20px;
}
.rating-name {
    font-size: 16px;
    color: var(--dark-color);
    font-weight: 400;
}
.c100 {
    position: relative;
    font-size: 120px;
    width: 1em;
    height: 1em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    float: left;
    margin: 0 0 0.1em 0;
    background-color: #cccccc;
}
.c100 > span {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    width: 5em;
    line-height: 5em;
    font-size: 0.2em;
    color:#ccc;
    display: block;
    text-align: center;
    white-space: nowrap;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.c100 .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.green .bar, .c100.green .fill {
    border-color: #89C53E !important;
    box-sizing: content-box;
}
.c100:after {
    position: absolute;
    top: 5px;
    left: 5px;
    display: block;
    content: " ";
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: whitesmoke;
    width: 110px;
    height: 110px;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}
.c100 .bar {
  position: absolute;
    border: 0.08em solid #307bbb;
    width: 0.84em;
    height: 0.84em;
    clip: rect(0em, 0.5em, 1em, 0em);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
.c100.p25 .bar {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.post-date {
    margin-right: 20px;
}
.patient-reviews p {
    width: 90%;
}
.ratings-outer {
    border-bottom: solid 2px #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.date-column-outer {
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.carousel-control.left, .carousel-control.right {
  background: none;
  width: 5px;
  height: 1px;
  display: none;
}
.time-slot {
  margin: 10px 8px;
}
.time-slot a {
    background: #e3ffc1;
    color: var(--ex-dark-color);
    padding: 10px 20px;
    display: block;
    width: 100%;
    font-weight: 500;
}
.next:before {
  display: none;
}
.date-heading {
    font-weight: 600;
    color: #26763A;
    text-align: center;
    font-size: 24px;
    clear: both;
    overflow: hidden;
    border-bottom: solid 2px;
    margin-bottom: 10px;
}
.glyphicon-chevron-left {
  border-right: 8px solid var(--dark-grey);
}
.glyphicon-chevron-right {
  border-left: 8px solid var(--dark-grey);
}
.glyphicon-chevron-left, .glyphicon-chevron-right {
    width: 0px;
    height: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    margin: 0px;
    color: var(--dark-grey);
    opacity: 1;
}
.carousel-control .glyphicon-chevron-left {
  left: 10px;
  margin: 0px;
  height: 10px;
  width: 10px;
  top: 10px;
}
.carousel-control .glyphicon-chevron-right {
  right: 10px;
  top: 10px;
  margin: 0px;
  height: 10px;
  width: 10px;
}
.location {
    margin: 20px 0;
    font-size: 16px;
    clear: both;
    overflow: hidden;
    color: var(--dark-grey);
}
.location span {
    float: left;
    width: 25px;
}
.address {
    float: left;
    width: 90%;
}
.review-details {
    display: flex;
    margin-top: 5px;
}
.review-type {
    margin: 0 10px 0 0;
    color: #666;
    font-weight: 600;
}
.patient-reviews {
    padding-bottom: 20px;
    border-bottom: solid 2px #eee;
    margin-bottom: 20px;
    display: flex;
}
.patient-reviews:last-child {
  border-bottom: 0px;
}
.user-firs-letter {
    width: 30px;
    height: 30px;
    text-align: center;
    margin: -5px 30px 0 0;
    border-radius: 50%;
    padding: 5px 0 0;
    color: #5e5e5e;
}
.review-wrap h4 {
    margin: 0px;
    font-size: 18px;
    margin-bottom: 10px;
}
.user-firs-letter.default-color {
  background: #ccc;
}
.user-firs-letter.light-blue {
  background: #c6ecff;
}
.user-firs-letter.light-green {
  background: #c6ffd9;
}
.on-location {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: solid 2px #666;
}
.on-location:last-child {
  border-bottom: 0px;
}
.single-appointment > .modal-dialog {
    width: 50%;
    position: relative;
}
.profile-view {
  background: #e3ffc1;
}
.single-appointment .modal-body {
  padding: 0 15px;
}
.single-appointment .doctor-name {
  font-size: 26px;
}
.single-appointment .modal-header {
    padding: 0;
    border: 0;
}
.single-appointment button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
}
.doctor-image {
    text-align: center;
    margin: 30px 0 15px;
}
.doctor-image img {
    border-radius: 50%;
    border: solid 2px;
}
.single-appointment .rating-stars {
    margin-bottom: 20px;
}
.single-appointment .rating-stars .star:nth-last-child(2) svg {
  color:  #666;
}
.single-appointment .doctor-specialties {
  margin: 10px 0;
}
.single-appointment .location {
  color: #26763A;
}
.appointment-view-datetime {
    display: flex;
    font-size: 22px;
    font-weight: 600;
    margin: 20px 0;
    color: var(--dark-grey);
}
.appointment-date {
    margin-right: 30px;
}
.single-appointment h3 {
  font-size: 16px;
  margin-bottom: 10px;
}
.single-appointment .location {
    margin: 30px 0 10px;
}
.carousel-inner > .item {
  transition: inherit;
  -webkit-transition: inherit;
}
.date-column .time-slot-column:first-child .time-slot {
    margin-left: 0px;
}
.date-column .time-slot-column:last-child .time-slot {
  margin-right: 0px;
}
.doctorlisting-page .carousel-control.left, .doctorlisting-page .carousel-control.right {
  display: block;
}
.doctorlisting-page .date-heading-wrap {
  display: flex;
  justify-content: space-between;
}
.doctorlisting-page .calendar-nav, .doctorlisting-page .doctor-listing-section {
    background: #fff;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 15px;
}
.doctorlisting-page .time-slot-column {
  display: flex;
  justify-content: space-between;
}
.doctorlisting-page .time-slot a {
  padding: 10px 15px;
}
.doctorlisting-page .time-slot {
  margin-bottom: 0px;
}
.doctorlisting-page .doctor-profile-image {
    float: left;
    margin-right: 20px;
}
.doctorlisting-page .doctor-profile-image img {
  height: 140px;
  width: 140px;
}
.doctorlisting-page .doctor-name {
  font-size: 24px;
  margin-top: -5px;
}
.doctor-description {
    float: left;
    width: 70%;
}
.doctorlisting-page .address {
  float: inherit;
  width: 100%;
}
.doctorlisting-page .doctor-specialties {
  font-size: 18px;
}
.doctorlisting-page .location h4 {
  color: #26763A;
}
.calendar-nav .date-heading {
  border-bottom: 0;
  color: var(--dark-color);
}
.doctorlisting-page .time-slot a {
  background: #eee;
}
.calendar-listing-section .date-column:first-child .time-slot {
  margin-top: 0px;
}
.doctorlisting-page .doctor-listing-section:hover .time-slot a {
  background: #e3ffc1;
}
.doctorlisting-page .doctor-listing-section:hover .time-slot a:hover, .doctorlisting-page .doctor-listing-section:hover .time-slot a:focus {
  background: #26763A;
  color: #fff;
}
.doctorlisting-page #stars {
  margin: 0px;
}
.doctorlisting-page .review a {
  font-size: 16px;
}
.doctorlisting-page .doctor-listing-section {
    min-height: 280px;
}
.doctorlisting-page .map iframe {
  width: 100%;
  height: 100vh;
}
.dropdown-list ul {
    margin: 0;
    padding: 0;
    border: solid 1px;
    background: #fff;
    border-top: 0px;
    display: none;
}
.dropdown-list ul li {
    list-style: none;
    font-size: 14px;
    padding: 10px 5px;
    color: #666;
    border-bottom: solid 1px #eee;
}
.dropdown-list ul li:hover {
    background: #eee;
    cursor: pointer;
}
.search-doctor {
    display: flex;
    justify-content: center;
    padding: 12% 0 0;
    background: #f1f1f1;
    background-position: top center;
    background-size: cover;
    height: 100vh;
}
.search-doctor h1 {
  color: var(--ex-dark-color);
}
.search-doctor .col-md-5.col-sm-5, .search-doctor .col-md-12 {
    padding: 1px;
}
.search {
    margin: 6px 0;
    font-size: 17px;
    padding: 7px 15px;
}
.dropdown-list ul li span {
    float: right;
    color: #bbb;
    margin-right: 5px;
}
.dropdown-list ul li svg {
    font-size: 24px;
    background: #eee;
    padding: 6px;
    border-radius: 50%;
    color: #ccc;
    float: left;
    margin-right: 10px;
    cursor: pointer;
}
.front-header {
    background: var(--theme-bg-color);
    height: 70px;
}
