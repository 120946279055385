@media only screen and (max-width: 767px) {
    .d-menu{
        cursor: pointer;
    }

    ul.navigation {
        animation:slideDown 1s ;
        cursor: pointer;
        
    }

    .slideview ~ ul.navigation {
        display: block;
      
    }

    @keyframes slideDown{
        from {
          transform: translateY(-2%);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }


    .whiteHeader ul li{
        padding-left:15px!important;
    }
    .business-search .table-search .table-search-input{
        width:auto;
    }

    .login img{
        max-width: 100%;
    }
   
    .footer .float-left{
        float: none !important;
        text-align:center;
    }
    .footer .float-right{
        float: none !important;
        text-align:center;
    }
    .hed-mob  {
       
    padding-bottom: 7px;
    }  
    .tab-data{
        position: relative;
    }
    .tab-data .nav-tabs{       
    /* transform: rotate(90deg); */
    /* position: relative; */
    /* left: 0; */   
    font-size: 9px;
    /* background: #fff; */
    
    }
    .tab-content {
        /* overflow: unset; */
        /* float: left; */
        font-size: 14px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {

    .tiles{
        margin-top:15px;
    }

    .login img{
        max-width: 100%;
    }

    .whiteHeader ul li{
        padding-left:7px;
        padding-right:7px;
    }

    .myaccount {
        position: absolute;
        cursor: pointer;
        top: 56px;
        z-index: 9999;
        right: 0;
        color:#fff;
    }
}

@media only screen and (max-width: 767px) {
  
    .navigation li .active:after, .navigation li:hover:after{
        content: none;
    }
    .myaccount .dd ul li{
        line-height: normal;
    }
    .whiteHeader ul li {
        list-style: none;
        padding-left: 3px;
        padding-right: 4px;
        line-height: 36px;
        text-align: left;
        font-weight: bold;
    }
    .navigation li a.active{
        color:#edcf7e;
    }
 
    .myaccount .dd ul {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        top: 33px;
        right: 0px;
        width: 150px;
        background-color: #ffffff;
        font-weight: bold;
        position: absolute;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        display: block;
        text-align: right;
        height: auto;
        border-radius: 8px;
        border: solid 1px rgba(36,152,237,0.8);
    }

    .myaccount ul li a {
        color: #474747!important;
    }
    

.dd ul{
    right:none!important;
    left:-48px !important;
}


    .topHeaderBlue--inner{
        font-size: 10px;
    }
    .before-login {
        padding-left: 0;
    }
    .whiteHeader ul{
       display: none;
      }
      .d-menu{
          float:left;
      }
      .myaccount{
          margin-top:0;
      }
      .tiles{
          margin-top:20px;
      }

      .whiteHeader ul{
        display: none;
        position: absolute;        
        text-align: center;
        width:100%;
       padding-bottom: 20px;
       padding-top:20px;
        z-index: 999;
        background:#2498ed;
        left: 0;
       }
       .typoHome img {
        display: inline-block;
        margin-top: 13px;
    }
    .photo-slider {
        /* background-image: url(/static/media/homeslider.1e7aa3ac.png); */
        background-repeat: no-repeat;
        height: 153px;
        position: relative;
    }

       .whiteHeader ul li a{
           color:#fff
       }
       .tiles .m-t-30{
           margin-top:0;
       }

       .tile{
           margin-bottom: 20px;
       }

       .hed-mob{
           background:#efefef;
           padding-top:10px;
       }

}

