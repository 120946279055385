@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap');


html, body{
  background:#fff!important;
}

body{
  font-family: 'Montserrat', sans-serif!important;  
}
html, body {
  height: 100%;
  margin: 0;
}
.wrapper {    
  min-height: calc(100vh - 200px);
  background:#efefef; 
  padding-bottom: 30px; 
  padding-top: 30px;
}
.wrapper:after{
  content: "";
	display: block;
  clear: both;
  overflow: hidden;

}
.calendar{
  position: absolute;
  right: 9px;
  top: 35px;
  z-index: 1;
  pointer-events: none;
}
.datep{
  position: relative;
}

.wrapper2 {    
  min-height: calc(100vh - 46px);
  background:#fff;
  overflow: hidden;
  
}
.dashboard-wrapper{
  min-height: calc(100vh - 140px);
  background:#efefef;
  overflow: hidden;
  padding-bottom: 30px;
}
.form-control{
  border:solid 1px #ddd !important;
  font-size: 13px!important;
}
.has-error .form-control{
  border:solid 1px #ac1818!important;
}

.nav{
  display: flex;
  padding-right:10px;
}

.nav-item{
  float: left;
}

.nav-item a{
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  background: var(--light-color)
}
.topHeaderBlue--inner{
font-size:13px;
}
.logo-inner{
  height: 63px;
  display: inline-block;
  margin-right:5px;
}


.footer img{
  display: inline-block;
    margin-top: -2px;
}

.nav-item a:hover {
  background-color: #4CAF50;
  color: white;
}


.check-control label{
display: inline-block;

}
.note-emp-details{
border-bottom:dotted 1px #ddd;
padding-bottom: 0;
text-align: left;
margin-bottom: 30px;
}

/*Employee details*/
.emp-details label{
  font-weight: 400 !important;
}
.emp-details h4{
  display: block;
  width: 100%;
}
.emp-details h4{
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  font-weight: 600 !important;
  font-size: 16px;

}

/*General CSS*/
.blue{color:#2498ed;}
.white{color:#fff !important;}
.grey{color:#474747;}
.yellow{color:#edcf7e !important;}
.p-r-10{padding-right:10px;}

/*header css*/
.topHeaderBlue{
  background-color:#2498ed;
  height: 20px;
}
.socialIcon{
  font-size:16px;
  fill: #edcf7e;
}
.whiteHeader{
  background-color:#fff;  
  position: relative;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.04);
  border-color: rgb(221, 221, 221);

}
.remember-checkbox{
  font-weight: 500;
  font-size: 13px;
}

.login--left{
  position: relative;
  height: calc(100vh - 150px);
}
.shadow-line{
  position: absolute;
  width:50px;
  background-image: url(../src/assets/images/shadow-line.png);
  background-repeat: repeat;

  top:0;
right:0;
  height: 100%;
}
.login h2{
  margin-top:50px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 30px;
  color:#2498ed;
  padding-bottom: 30px;
}
.custom-control-label::before, .custom-control-label::after{
  width:1.5rem;
  height:1.5rem;
 
}
.custom-control-input:checked~.custom-control-label::before{
  border-color:#2498ed;
  background-color: #2498ed;
}

.custom-control-label{
  padding-left:10px;
}
.rt-resizable-header-content, .rt-th{
  color: #2498ed;
}
.rt-thead div{
  color: #fff;
}
.loginIcon{ 
    float: left;
    margin: -2px;
    margin-right: 2px;
}
.forgot-password {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 13px;
 
}
.forgot-password a{
  color:#2498ed!important;
}
.before-login{
  padding-left: 40px;
}
.m-b-50{
  margin-bottom: 50px;
}
.before-login small{
  font-size: 16px!important;
}
.btn.blue{
  background:#2498ed;
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: linear;
  border:solid 1px #2498ed;
}

.btn.blue:hover{
  background: #edcf7e;
  box-shadow: none;
  border:solid 1px #ddd;
}

.btn.grey{
  background:#fff;
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: linear;
  border:solid 1px #ddd;
  color:#2498ed;
  border:solid 1px #2498ed;
}
.btn.grey:hover{
  background:#ddd;
  border:solid 1px #ddd;
  color:#fff;
  box-shadow: none;
}
.btn.grey-icon{
  background:#fff;
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: linear;
  border:solid 1px #ddd;
  color:#2498ed;  
  padding:2px 5px;
}
.btn.grey-icon:hover{
  background:#ddd;
  border:solid 1px #ddd;
  color:#fff;
  box-shadow: none;
}
.before-login h1{
  font-size: 25px;
  color:#474747;
  font-weight: 600 !important;
}
.myaccount{
  
    float: right;
    margin-top: 24px;
}

.whiteHeader ul{
  margin:0;
  padding:0;
 display: flex;
  float:left;
  margin-top:25px;
  margin-left: 20px;
}
.whiteHeader ul li{
 list-style:none; 
 padding-left:13px;
 padding-right:13px;
 
}
.w-100{
  width:100%;
}

.lmenu{ 
  display: block; 
}
.lmenu:hover{
  -webkit-transition: color ease-in-out 800ms;
  transition: color ease-in-out 800ms;
  color: #2498ed;
}
.navigation{
  margin-top: 15px;
}

/*Slider*/

.inner-slider{
  background-image:url(../src/assets/images/innerslider.jpg);
  background-repeat: no-repeat;  
  position: relative; 
  height: 60px;
}
.inner-slider h2{
  color:#fff;
  text-align: center;
  padding-top:15px;
}
.photo-slider{
  background-image: url('../src/assets/images/homeslider.png');
  background-repeat: no-repeat;
  height:222px;
  position: relative;
 
}
.typoHome img{
 display: inline-block;
 margin-top:60px;
 
}
.blueStrip{
  height: 36px;
  background:rgba(36,152,237,0.8);
  position: absolute;
  bottom:0;
  width:100%;
}
.blueStrip h2{
  font-size: 20px;
  color: #fff;
  line-height: 36px;
}
.navigation li{
  position: relative;
}
.navigation li:hover:after{
  content: '';
    background: #edcf7e;
    width: 100%;
    position: absolute;
    bottom: -29px;
    height: 2px;
    left: 0;
}
.css-4je539-control{
  border:solid 1px #ddd!important;
}
.business-heading h3{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
  
}

.business-heading a{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px; 
  border-bottom: solid 1px #ddd;
  
}
.upload-logo{
  background:#efefef;
  padding:20px;
}
.business-heading h4{
  font-size: 16px;
  font-weight: bold; 
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
  
}
.date-disabled{
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  margin-left: 10px;

}
.address-details{
  border: solid 1px #dbdef5;
    margin-bottom: 10px;
    padding: 10px;
    background: #f6f7ff;
}
.doc-list{
  z-index: 999;
  position: relative;
  background: #d4e6f3;
  padding: 13px 8px;
  margin-bottom: 28px;
}
#ListA{
  z-index: 1;
  position: relative;
}
.text-left-ab{
  text-align: left;
  float:left;
  font-weight: bold !important;
  padding-left:15px;
}

.myaccount{
  position: relative;
  cursor: pointer;
}
.myaccount ul:after, .myaccount ul:before {
	bottom: 100%;
	left: 80%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.myaccount ul:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #fff;
	border-width: 12px;
	margin-left: -30px;
}
.myaccount ul:before {
	border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #2498ed;
    border-width: 14px;
    margin-left: -32px;
}

.navigation li a.active{
  color:#2498ed;
}

.navigation li .active:after {
  content: '';
  background: #edcf7e;
  width: 100%;
  position: absolute;
  bottom: -29px;
  height: 2px;
  left: 0;
  
}

/*Footer*/
footer{
  border-top:solid 1px #ddd;
  padding-top:14px;  
  font-size: 12px;
  background:#2d445f;
  color:#efefef;
  padding-bottom: 14px;
}
.footer ul{
  margin:0;
  padding:0;
}
.footer ul li{
display: inline-block;
padding-right:10px;
}
.footer li a{
  color:#efefef;
}

/*Business List*/
.business-list h2, .bg-boom{
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.rt-tr .odd{
  background-color: #efefef!important;
}



.business-list .container, .bg-boom, .fp-container{
  padding: 30px 30px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background:#fff;  
  margin-bottom: 30px;
}
.btn{
  border-radius: 5px!important;
}
.form-control{
  border-radius:5px!important;
}

@media (min-width: 1200px){
  .business-list .container{
    width: 900px;  
  }
  .bg-boom .container{
    width: 1170px;  
  }

  .fp-container .container{
    width: 500px;   
  }
  
}
.business-list-table{
  width:100%;  
}
.tableicons .grey{
  background-color: #fff;
  border:solid 1px #ddd;
}
.tableicons .table-btn{
  min-width:28px;
}
.business-list-table .rt-thead {
  background-color: #eeeeee;
  color:#2498ed!important;
}
.business-search{
  margin-bottom: 20px;
  position: relative;
}
.business-location{
  margin-bottom: 30px;
}
/* .ReactTable .-pagination .-pageInfo{
  display: none!important;
}
.-pagination .-center{
  width:8px;
}

.pagination-bottom{
  overflow: hidden;
  text-align: center;
  margin-top:15px;
  clear: both;
}
.pagination-top{
  position: absolute;
    bottom: -98px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    /* transform: translate(-32%, 33%); */
    /* left: -293px; */
    /* right: 0; */
  /* left: -293px; */
/* } */ 

.location-select .css-2b097c-container, .location-select .css-2b097c-container{
  z-index: 99;
} 
.success{
  color:#49d639;
}
.danger{
  color:red;
}

a{
  color:#2498ed;
}

.modal-header{
  background-color:#2498ed;
 
}
.modal-title{
  color:#fff !important;
}

.business-search .table-search{
  position: relative;
 
}
.business-search .table-search .table-search-input{
  width: 300px;
  border-radius: 5px !important;
  border:solid 1px #ddd !important;
}
.business-search .table-search span.text-search{
  position: absolute;
  left:10px;
  top:6px;
}

.business-search .table-search .icon{
  font-size: 20px;
  margin-left: 9px;
}
.ReactTable .rt-td{
  font-size: 13px;
}

/*Pagination*/
/* .Table__itemCount {
  
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
} */
/* .-center, .select-wrap.-pageSizeOptions{
  display: none;
}
.ReactTable .-pagination .-pageInfo{
  display: none;
}
.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  flex: 1 1;
  text-align: center;
  border: solid 1px #ddd;
  width: 78px;
} */

.pagination-bottom{
  margin-top:15px;
}

.fade {
  opacity: 1 !important;
  transition: opacity 0.15s linear;
}

/*Table*/
.ReactTable .rt-thead .rt-th{
  background-color:#8688cc;
  color:#fff;
}

/*alerts*/
.alert h4{
  margin-bottom: 0;
}

/*Tab Content*/
.tab-content > .active { 
  clear: both;
  float: left;
  width: 100%;
  padding: 20px;
background:#fff;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
hr{
  border-color:#ddd!important;
}
.brround{
  border-radius: 5px;
  border:solid 1px #ccc;
  height: 30px;
}

.tab-content .form-group{
  text-align: left;
}
.tab-content h3{
  text-align: left;
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
}
.tab-data .nav-tabs .nav-link {
 
  background: #8688cc;
  color: #fff;
  margin-right: 5px;
}
.tab-data h1{
  font-size: 24px;
}
.tab-data .nav-tabs .nav-link.active{
  color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.slid{
  box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow:0 8px 6px -6px black;
  -webkit-box-shadow:0 8px 6px -6px black;
}

/*Form css*/
.check-control{
  margin-left:50px;
  margin-bottom: 10px;
  position: relative;
}
.check-control input[type="checkbox"] {
   opacity: 0;
   position: absolute;
   z-index:9999;
   left:-50px;
   height: 17px;

}
/* Checkbox Icons */
.check-control label {
  position: relative;
  padding-left: 30px;  
  cursor: pointer; 
  padding: 0;
  margin-bottom:0;
}

.check-control label:after {
  font-family: FontAwesome;
  font-size: 30px;
  /*absolutely positioned*/
  position: absolute;
}
.check-control label:before {
  content:'';
  position: absolute; top: 0; left: -49px; 
  font-family: FontAwesome;
  font-size: 30px;
  width:20px;
  height: 20px;
  border:solid 1px #ddd;
  position: absolute;
  border-radius: 5px;

}
.check-control label:after {
  content: '\f00c'; /*checkbox checked*/
  max-width: 0;
  overflow: hidden;
  opacity: 0.5;
  font-size: 20px;
 top: -5px;
 left: -48px;
 color: #f2ca27;

-webkit-transition: all 0.50s;
  -moz-transition: all 0.50s;
  -o-transition: all 0.50s;
   transition: all 0.50s;
}
/* Animating the Checkbox Icon */
.check-control input[type="checkbox"]:checked + label:after {
  max-width: 25px;
  opacity: 1;
  margin-right: 90px;
}


/*Form css*/
.check-control1{  
  position: relative;
  margin-left:44px;
}
.check-control1 input[type="checkbox"] {
   opacity: 0;
   position: absolute;
   z-index:9999;   
   height: 17px;
   left:-30px;

}
/* Checkbox Icons */
.check-control1 label {
  position: relative; 
  cursor: pointer; 
  padding: 0;
  margin-bottom:0;
}

.check-control1 label:after {
  font-family: FontAwesome;
  font-size: 30px;
  /*absolutely positioned*/
  position: absolute;
}
.check-control1 label:before {
  content:'';
  position: absolute; top: 0; left: -29px; 
  font-family: FontAwesome;
  font-size: 30px;
  width:20px;
  height: 20px;
  border:solid 1px #ddd;
  position: absolute;
  border-radius: 5px;

}
.check-control1 label:after {
  content: '\f00c'; /*checkbox checked*/
  max-width: 0;
  overflow: hidden;
  opacity: 0.5;
  font-size: 20px;
 top: -5px;
 left: -28px;
 color: #f2ca27;

-webkit-transition: all 0.50s;
  -moz-transition: all 0.50s;
  -o-transition: all 0.50s;
   transition: all 0.50s;
}
/* Animating the Checkbox Icon */
.check-control1 input[type="checkbox"]:checked + label:after {
  max-width: 25px;
  opacity: 1;
  margin-right: 90px;
}



.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  box-shadow: 1px 1px 2px rgb(14, 153, 255) !important;
  background: #425e73!important;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px!important;
}
.btn-primary:active:focus{
  background-color: #425e73!important;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545!important;
}

.badge-success {
  color: #fff;
  background-color: #28a745!important;
}

/*Date Picker*/
.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 2px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}

.css-1wa3eu0-placeholder{
  color:#9f9f9f!important;
}
.custom-url span {
  display: inline-flex;
  padding-top: 15px;
  padding-right: 10px;
  float: left;
}
.powered-by{
  float:right;
  /* border-left: solid 1px #efefef; */
  margin-left: 10px;
}
.pos-relative{
  position: relative;
}
.adjust-button{
  position: absolute;
  right: 0px;
  top: -20px;
}
.online-form{
  max-height: 500px;
  overflow-y: auto;
}
.p-l-zero{
  padding-left: 0 !important;
  margin-left: 0;
  margin-top: 15px;
}
.space-btm-4px{
  margin-bottom: 4px;
}
.new-link{
  color: #2498ed;
}
.online-image{
  display: flex;
  height: 70%;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.select-status .col-md-6{
  margin: 0;
  padding: 0;
}
.powered-by-owner{
  display: flex;
  order: 2;
  border-left: solid 1px #efefef;
  margin-left: 5px;
}

.wfour-prepend, .afour-prepend {
    height: 40px;
    margin-top: 5px;
}

.input-group .form-control:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.consent-form-link { 
  text-decoration: underline;
}

/* Define css class for global loading message to cover 
   screen during axios operations */

   .loading-indicator:before {
    content: '';
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.8;
}

.loading-indicator:after {
    content: url(./assets/images/profile-loading.gif);
    position: fixed;
    width: 100%;
    top: 25%;
    left: 0;
    z-index: 1001;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.5rem;    
    opacity: 0.8;    
}

.btn.danger-icon {
  background: #fff;
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: linear;
  border: solid 1px #ddd;
  color: #ef180d;
  padding: 2px 5px;
}

.online-image img {
  width: 100%;
}

.delete-doc {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #2498ed;
  color: white;
  border-radius: 20px;
  margin-left: 5px;
  text-align: center;
  line-height: 17px;
  border: 1px solid #2498ed;
  transition: ease-in .3s all;
}

.delete-doc:hover {
  color: #2498ed;
  border: 1px solid #2498ed;
  background: #fff;
}

p.confirm-delete {
  display: flex;
  margin-left: 40px;
}

p.confirm-delete input[type="checkbox"] {
  margin-top: 4px !important;
}

.react-confirm-alert-body {
  text-align: left !important;
}

.react-confirm-alert-body h1 {
  text-align: center;
}

.react-confirm-alert-button-group > button {
  background: #2498ed !important;
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: linear;
  border: solid 1px #2498ed !important;
}

.acknowladge .check-control {
  margin-left: 24px;
}

.acknowladge .check-control label{
  font-weight: 400;
}

.acknowladge .check-control label:before,
.acknowladge .check-control label:after {
  left: -30px;
}

.acknowladge .check-control input[type="checkbox"] {
  left: -15px;
}
.acknowladge .check-control input[type="checkbox"] {
  cursor: pointer;
}

/* alert button */
.error-btn {
  border: 1px solid red !important;
  color: red !important;
}

.success-btn {
  border: 1px solid green !important;
  color:green  !important;
}

.containerBox p a{
  display: none;
}

.confirm-alert-button {
  margin-right: 8px;
    border-color: rgb(40, 96, 144) !important;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px;
    background: rgb(40, 96, 144) !important;
    color: white;
}

.sweet-alert.containerBox {
  overflow: visible !important;
}

/* Upgrade CSS Style */

button:focus {
  outline: none;
}

.slider-wrapper .slider .slide .slid {
  height: 100%;
}

.slider-wrapper .slider .slide .slid img {
  height: 100%;
  object-fit: cover;
}

.slider-wrapper .slider .slide.previous::before {
  display: none;
}

.bg-info, .bg-warning, .bg-primary {
  background-color: #999999 !important;
  color: #fff !important;
}

.action-button-container {
  display: flex;
  justify-content: center;
  padding-left: 5px;
  gap: 5px;
}

.action-button-wrapper {
  display: flex;
  /* justify-content: left; */
  gap: 5px;
}

.action-button-container .btn + .btn {
  margin-left: 0;
}

.modal-open .modal-content .modal-header .modal-title.h4 {
  margin-right: auto;
}

.business-list .business-search.row .alert.alert-success {
  display: flex;
  gap: 2rem;
  margin: 1rem 0 0;
  max-width: min-content;
  align-items: center;
  margin-left: auto;
}

.whiteHeader .row img {
  object-fit: contain;
}

.applicant-detail-modal .modal-header .btn-close {
  position: relative; 
  width: 25px;
  height: 25px;
  background: #0000;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex; 
}
.applicant-detail-modal .modal-header .btn-close::after {
  position: absolute;
  content: '\2715';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  color: #000;
  font-size: 1.65rem;
}

button.datepicker__navigation {
  height: 15px;
  width: 15px;
}
.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 5px;
}
.react-datepicker__header__dropdown .react-datepicker__month-dropdown-container--select, .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container--select {
  margin: 0;
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
  left: -4px;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
  right: -4px;
}